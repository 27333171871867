import * as React from "react";
import { Global, css } from "@emotion/core";
import { Helmet } from "react-helmet";
import Stars from "./Stars";

const globalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html,
  #___gatsby {
    width: 100%;
    height: 100%;
    font-family: Helvetica;
  }
  body {
    background-image: linear-gradient(
        -203deg,
        #5ec8de 0%,
        #8971ff 36%,
        #c185ab 81%,
        #c47878 100%
      ),
      linear-gradient(0deg, rgba(255, 255, 255, 0) 15%, rgba(0, 0, 0, 0.3) 98%);
    background-attachment: fixed;
  }
`;

const layoutStyles = css`
  display: flex;
  width: 100%; 
  height 100%;
`;

const canvasContainer = css`
  position: absolute;
  z-index: 1;
  position: fixed;
`;

const ontopGradient = css`
  z-index: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 15%,
    rgba(0, 0, 0, 0.1) 98%
  );
`;

const contentStyles = css`
  position: relative;
  z-index: 2;
`;

interface LayoutProps {
  children: any;
}

const Layout: React.FunctionComponent<LayoutProps> = props => {
  return (
    <div css={layoutStyles}>
      <Helmet>
        <title>Karen Vertierra</title>
        <link rel="icon" href="favicon.png" type="image/x-icon" sizes="16x16" />
        <link
          rel="icon"
          href="favicon@2x.png"
          type="image/x-icon"
          sizes="32x32"
        />
        <link
          rel="icon"
          href="favicon@3x.png"
          type="image/x-icon"
          sizes="48x48"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Nixie+One"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Space+Mono"
          rel="stylesheet"
        />
        >
      </Helmet>
      <Global styles={globalStyles} />
      <div>
        <div css={ontopGradient} />
        <div css={canvasContainer}>
          <Stars starCount={1000} />
        </div>
        <div css={contentStyles}>{props.children}</div>
      </div>
    </div>
  );
};

export default Layout;
