import * as React from "react";
import { css, keyframes } from "@emotion/core";
import { filter, compose, reduce, map, remove, mapAccum } from "ramda";
import { useWindowSize } from "../utils/hooks";

const fadeIn = keyframes`
    to {
        opacity: 1;
    }
`;

const generateStars = (
  count,
  width,
  height,
  xParam = 0,
  yParam = 0,
  xMod = 0,
  yMod = 0
) => {
  const stars = new Array();

  for (var i = 0; i < count; i++) {
    const starProps = {
      x: xParam !== 0 ? xParam + xMod : Math.random() * width + xMod,
      y: yParam !== 0 ? yParam + yMod : Math.random() * height + yMod,
      r: Math.random() * 2.5,
      o: Math.random()
    };
    stars.push(starProps);
  }

  return stars;
};

const canvasStyles = css`
  animation: ${fadeIn} ease-out 3s forwards;
  opacity: 0;
  mask-image: linear-gradient(rgba(0, 0, 0, 1), transparent);
`;

interface StarsProps {
  starCount: number;
}

const Stars: React.FunctionComponent<StarsProps> = props => {
  const canvasRef = React.useRef();
  const [renderStyles, setRenderStyles] = React.useState(false);
  const [windowWidth, windowHeight] = useWindowSize();

  let stars = generateStars(
    windowWidth < 500 ? props.starCount / 3 : props.starCount,
    windowWidth,
    windowHeight,
    0,
    0,
    0,
    0
  );

  const draw = () => {
    const ctx = canvasRef.current.getContext("2d");

    ctx.clearRect(0, 0, windowWidth, windowHeight);

    let newStars = new Array();

    for (var i = 0; i < stars.length; i++) {
      const s = stars[i];

      if (s.x > windowWidth || s.y < 0) {
        const axis = Math.random() * 10 < 7;
        newStars.push({
          x: axis ? Math.random() * windowWidth : 0,
          y: axis ? windowHeight : Math.random() * windowHeight,
          r: s.r,
          o: s.o
        });
      } else {
        const opacity = s.o;
        // Draw star
        ctx.beginPath();
        ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
        ctx.arc(s.x, s.y, s.r, 0, Math.PI * 2);
        ctx.fill();
        ctx.closePath();
        // Push star to new array
        newStars.push({
          x: s.x + s.r / 10,
          y: s.y - s.r / 5,
          r: s.r,
          o: opacity
        });
      }
    }

    stars = newStars;

    window.requestAnimationFrame(draw);
  };

  React.useEffect(() => {
    const elem = canvasRef.current;
    const ctx = elem.getContext("2d");

    elem.width = windowWidth * window.devicePixelRatio;
    elem.height = windowHeight * window.devicePixelRatio;
    elem.style.width = windowWidth;
    elem.style.height = windowHeight;
    ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    setRenderStyles(true);

    window.requestAnimationFrame(draw);
  }, [windowWidth, windowHeight]);

  return (
    <>
      <canvas
        ref={canvasRef}
        css={canvasStyles}
        style={
          renderStyles
            ? {
                width: `${windowWidth}px`,
                height: `${windowHeight}px`
              }
            : null
        }
      />
    </>
  );
};

export default Stars;

Stars.defaultProps = {
  starCount: 100
};
