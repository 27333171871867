import * as React from "react";

export const useWindowSize = () => {
  const [windowWidth, setWindowWidth] = React.useState(
    typeof window !== "undefined" && window.innerWidth
  );
  const [windowHeight, setWindowHeight] = React.useState(
    typeof window !== "undefined" && window.innerHeight
  );

  const handleResize = () => {
    // Figure out how to handle this in a performant way at some point
    // setWindowWidth(window.innerWidth);
    // setWindowHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [windowWidth, windowHeight];
};
