import * as React from "react";
import { css, keyframes } from "@emotion/core";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

const moveFrom = -30;
const timeOffset = 0.5;
const timing = 1.5;

const fadeIn = keyframes`
    to {
        opacity: 1
    }
`;

const fadeInMove = keyframes`
    to {
        opacity: 1;
        transform: translate(0, 0);
    }
`;

const overlayStyles = css`
  position: absolute;
  top 0;
  left: 0;
  width: 100%
  height: 100%;
  z-index: 0;
  background-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 15%,
    rgba(0, 0, 0, 0.3) 98%
  );
`;

const pageStyles = css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  z-index: 1;
`;

const navStyles = css`
  animation: ${fadeIn} ease-out ${timing}s forwards;
  animation-delay: 1s;
  width: 100%;
  opacity: 0;
  margin-top: 56px;
  margin-right: 56px;
  height: auto;
  display: flex;
  justify-content: flex-end;
  a {
    font-family: "Nixie One", Helvetica;
    margin-left: 32px;
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    @media (max-width: 800px) {
      font-size: 14px;
      margin-left: 16px;
    }
  }
  a:first-child {
    margin-left: 0;
  }
  @media (max-width: 800px) {
    justify-content: center;
    margin-right: 0px;
  }
`;

const contentStyles = css`
  width: 100%;
  height: auto;
  margin-top: 140px;
  margin-bottom: 128px;
  @media (max-width: 800px) {
    margin-top: 64px;
    margin-bottom: 64px;
  }
`;

const header1Styles = css`
  animation: ${fadeInMove} ease-out ${timing}s forwards;
  opacity: 0;
  transform: translate(0, ${moveFrom}px);
  font-family: "Nixie One", Helvetica;
  font-size: 60px;
  letter-spacing: 30px;
  text-transform: uppercase;
  color: white;
  font-weight: 300;
  // padding: 0 32px;
  @media (max-width: 800px) {
    font-size: 48px;
    letter-spacing: 16px;
  }
  @media (max-width: 620px) {
    font-size: 32px;
    letter-spacing: 8px;
  }
`;

const header1container = css`
  text-align: center;
`;

const hrStyles = css`
  animation: ${fadeInMove} ease-out ${timing}s forwards;
  opacity: 0;
  transform: translate(0, ${moveFrom}px);
  animation-delay: ${timeOffset}s;
  width: 10%;
  border: 1px solid white;
  margin: auto;
  margin-top: 64px;
  margin-bottom: 64px;
  @media (max-width: 800px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const paragraphStyles = css`
  animation: ${fadeInMove} ease-out ${timing}s forwards;
  opacity: 0;
  transform: translate(0, ${moveFrom}px);
  animation-delay: ${timeOffset * 2}s;
  a {
    color: white;
  }
  p {
    font-family: "Space Mono", Helvetica;
    margin: auto;
    text-align: center;
    color: white;
    font-weight: 100;
    margin-bottom: 32px;
    width: 50%;
    line-height: 34px;
    font-size: 16px;
    padding: 0 32px;
    @media (max-width: 1040px) {
      width: 50%;
    }
    @media (max-width: 800px) {
      width: 100%;
      font-size: 18px;
    }
  }
`;

const buttonStyles = css`
  display: block;
  text-decoration: none;
  text-align: center;
  background: rgba(255, 255, 255, 0.25);
  padding: 12px 32px;
  color: white;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  width: 200px;
  font-weight: 50;
  margin: auto;
  text-transform: uppercase;
  letter-spacing: 3px;
  border-radius: 8px;
  margin-top: 8px;
  box-shadow: 0;
  transition: box-shadow ease-out 0.5s, transform ease-out 0.5s;
  font-family: "Nixie One", Helvetica;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.5);
    transform: translate(0, -5px);
  }
  &:active {
    transform: translate(0, 0);
  }
`;

const email = "karenvertierra@gmail.com";

interface IndexPage { }

const IndexPage: React.FunctionComponent<IndexPage> = props => {
  return (
    <Layout>
      <div css={pageStyles}>
        <div css={navStyles}>
          <a href={`mailto:${email}`}>Say Hello</a>
          <a
            target="_blank"
            href="https://www.linkedin.com/in/karen-vertierra-3054578b/"
          >
            LinkedIn
          </a>
          <a target="_blank" href="https://www.pinterest.com/karenangelie/">
            Pinterest
          </a>
        </div>
        <div css={contentStyles}>
          <div css={header1container}>
            <h1 css={header1Styles}>Karen Vertierra</h1>
          </div>
          <hr css={hrStyles} />
          <div css={paragraphStyles}>
            <p>
              Karen is a Product Designer at Google. She loves working on early-stage ideas and values storytelling, experimentation, and collaboration.
            </p>
            <p>
              At Alphabet, Karen worked on the Google Search apps, Google Lens, and was a designer on Pixel where she worked on new hardware initiatives.
            </p>
            <p>
              She was also a UX Lead at X (formerly Google X), where she designed for industries such as robotics and oversaw AI-powered agriculture moonshots <a href="https://x.company/projects/tidal/" target="_blank">Tidal</a> and <a href="https://x.company/projects/mineral/" target="_blank">Mineral</a> to launch.
            </p>
            <a href={`mailto:${email}`} css={buttonStyles}>
              Say Hello
            </a>
          </div>
        </div>
      </div>
      <div css={overlayStyles} />
    </Layout>
  );
};

export default IndexPage;
